<template>
  <div :class="'item_detail '+noImgClass+' '+ layoutClass">
    <!-----Layout-1 Start---->
    <div class="lyout-cls d-flex align-items-center flex-grow-1" v-if="itemlayout == '1'">
      <div class="media media1" data-bs-toggle="modal" data-bs-target="#item-popup" @click="productModal(item)">
        <div class="item-img itemimg-squre" >
          <img :src="item.image" @error="onImgError()" v-if="!imgError"/>
        </div>
        <a :href="(pagename=='itemslist' || pagename == 'qr')?'javascript:void(0)':'/vendor/'+ slug+'#item'+menuId" id="element" class="btn btn-default show-modal py-0" >
          <div class="media-body">
            <div class="veg nveg" v-if="item.item_type == '0'"><span></span></div>
            <div class="non-veg nveg" v-if="item.item_type == '1'"><span></span></div>
            <h5 class="mt-0 me-5 item-name">{{ item.item_name }}</h5>
            <div class="prc-show">
              <span class="mt-2 d-inline-block item-price" v-if="item.price != '0'"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.price).toFixed(2)}}</span>
              <span class="mt-2 ms-2 d-inline-block item-price strikeout" v-if="item.mrp != item.price"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.mrp).toFixed(2)}}</span>            
            </div>
            <div class="tags-list mb-1" v-if="item.item_tags && item.item_tags.length > 0">
              <ul><li v-for="(tag,i) in item.item_tags" :key='i' :style="{ background: tag.background_color+'!important',color: tag.text_color}">{{tag.name}}</li></ul>
            </div>          
            <p class="mb-0 mt-2 item-descr" v-if="item.item_description">
              {{(item.item_description && item.item_description.length > 80 ? item.item_description.substring(0,80)+".." :item.item_description) }}
            </p>          
          </div>
        </a>
      </div>
      <!--Image with button start----->
      <div class="img-btn">
        <div class="pgnme-cls" v-if="pagename != 'qr'">
          <div v-if="!closeLabel" class="nw-itm-btn me-2">
            <div v-if="item.is_sold_out == '0'" class="sold-de">
              <div v-if="item.is_customization >= '1'" class="csm-de">
                <button class="add-to-cart btn btn-outline-warning btn-sm" data-bs-toggle="modal" data-bs-target="#item-popup" @click="productModal(item)" v-if="checkInCart(item.item_id)">{{$t('Add')}}</button>
                <div v-else>                  
                  <div class="quantityInc 1">
                    <div class="number d-flex align-items-center">
                      <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                      <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                      <span class="plus d-flex justify-content-center align-items-center" @click="productModal(item)">+</span>
                    </div>
                  </div>                  
                </div>
              </div>
              <div v-else class="icus">
                <button v-if="item.is_sold_out == '0' && checkInCart(item.item_id)" data-bs-toggle="modal" class="add-to-cart btn btn-outline-warning btn-sm" @click="addProductToCart(item)">{{$t('Add')}}</button>
                <div v-else >
                  <div class="quantityInc 2">
                    <div class="number d-flex align-items-center">
                      <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                      <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                      <span class="plus d-flex justify-content-center align-items-center" @click="plusItem(item)">+</span>
                    </div>
                  </div>
                </div>
              </div>
              <p v-if="item.is_customization" class="tag-customize tagcust-nw">{{$t('Customisable')}}</p>
            </div>
            <div v-else class="sold-bt">
              <button v-if="item.is_sold_out != '0'" class="sold add-to-cart btn btn-outline-warning btn-sm">{{$t('Sold Out')}}</button>
            </div>
          </div>
        </div>
        <!--Image with button end----->
      </div>
    </div>
    <!-----Layout-1 End---->
    <!-----Layout-2 Start---->    
    <div class="media media2" v-if="itemlayout == '2' ">
      <a :href="(pagename=='itemslist' || pagename == 'qr')?'javascript:void(0)':'/vendor/'+ slug+'#item'+menuId" id="element" class="btn btn-default show-modal" data-bs-toggle="modal" data-bs-target="#item-popup" @click="productModal(item)">
        <div class="media-body">
          <div class="veg" v-if="item.item_type == '0'"><span></span></div>
          <div class="non-veg" v-if="item.item_type == '1'"><span></span></div>
          <h5 class="mt-0 me-5 item-name">{{ item.item_name }}</h5>
          <div class="prc-show">
          <span class="mt-2 d-inline-block item-price" v-if="item.price != '0'"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.price).toFixed(2)}}</span>
          <span class="mt-2 ms-2 d-inline-block item-price strikeout" v-if="item.mrp != item.price"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.mrp).toFixed(2)}}</span>
          
          </div>
          <div class="tags-list mb-1" v-if="item.item_tags && item.item_tags.length > 0">
            <ul><li v-for="(tag,i) in item.item_tags" :key='i' :style="{ background: tag.background_color+'!important',color: tag.text_color}">{{tag.name}}</li></ul>
          </div>
          <p class="mb-0 mt-2 item-descr" v-if="item.item_description">
            {{(item.item_description && item.item_description.length > 80 ? item.item_description.substring(0,80)+".." :item.item_description) }}
          </p>
          
        </div>
      </a>
      <!--Image with button start----->
      <div class="img-btn">
        <div class="item-img itemimg-squre" >
          <img :src="item.image" @error="onImgError()" v-if="!imgError"/>
        </div>
        <div class="pgnme-cls btn-top position-relative" v-if="pagename != 'qr'">
          <div v-if="!closeLabel" class="nw-itm-btn">
            <div v-if="item.is_sold_out == '0'" class="sold-de">
              <div v-if="item.is_customization >= '1'" class="csm-de">
                <button class="add-to-cart btn btn-outline-warning btn-sm" data-bs-toggle="modal" data-bs-target="#item-popup"  @click="productModal(item)" v-if="checkInCart(item.item_id)">{{$t('Add')}}</button>
                <div v-else>                  
                  <div class="quantityInc 1">
                    <div class="number d-flex align-items-center">
                      <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                      <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                      <span class="plus d-flex justify-content-center align-items-center" @click="productModal(item)">+</span>
                    </div>
                  </div>                  
                </div>
              </div>
              <div v-else class="icus">
                <button v-if="item.is_sold_out == '0' && checkInCart(item.item_id)" class="add-to-cart btn btn-outline-warning btn-sm 5*" data-bs-toggle="modal" data-bs-target="#AlertModalPlaced" @click="addProductToCart(item)">{{$t('Add')}}</button>
                <div v-else >
                  <div class="quantityInc 2">
                    <div class="number d-flex align-items-center">
                      <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                      <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                      <span class="plus d-flex justify-content-center align-items-center" @click="plusItem(item)">+</span>
                    </div>
                  </div>
                </div>
              </div>
              <p v-if="item.is_customization" class="tag-customize tagcust-nw">{{$t('Customisable')}}</p>
            </div>
            <div v-else class="sold-bt">
              <button v-if="item.is_sold_out != '0'" class="sold add-to-cart btn btn-outline-warning btn-sm">{{$t('Sold Out')}}</button>
            </div>
        </div>
      </div>
    </div>
    <!--Image with button end----->
   </div>
    <!-----Layout-2 End---->
    <!-----Layout-3 Start---->
    <div class="media media3" v-if="itemlayout == '3'">
      <div :class="item.image ==''?'image-sec':''">
        <div class="item-img itemimg-squre" >
          <img :src="item.image" @error="onImgError()" v-if="!imgError"/>
        </div>
      </div>
      <div class="nme-sec">        
        <a :href="(pagename=='itemslist')?'javascript:void(0)':'/vendor/'+ slug+'#item'+menuId" id="element" class="btn btn-default show-modal" data-bs-toggle="modal" data-bs-target="#item-popup" @click="productModal(item)">
          <div class="media-body">
            <div class="veg" v-if="item.item_type == '0'"><span></span></div>
            <div class="non-veg" v-if="item.item_type == '1'"><span></span></div>
            <h5 class="mt-0 me-5 item-name">{{ item.item_name }}</h5>
            <div class="tags-list mb-1" v-if="item.item_tags && item.item_tags.length > 0">
              <ul><li v-for="(tag,i) in item.item_tags" :key='i' :style="{ background: tag.background_color+'!important',color: tag.text_color}">{{tag.name}}</li></ul>
            </div>
            <p class="mb-0 mt-2 item-descr" v-if="item.item_description">
              {{(item.item_description && item.item_description.length > 80 ? item.item_description.substring(0,80)+".." :item.item_description) }}
            </p>
          </div>
        </a>
      </div>
      <div class="prc-btn-sec">
        <div class="prc-show">
            <span class="mt-2 d-inline-block item-price" v-if="item.price != '0'"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.price).toFixed(2)}}</span>
            <span class="mt-2 ms-2 d-inline-block item-price strikeout" v-if="item.mrp != item.price"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.mrp).toFixed(2)}}</span>
          </div>
        <div v-if="!closeLabel" class="nw-itm-btn">
          <div v-if="item.is_sold_out == '0'" class="sold-de">
            <div v-if="item.is_customization >= '1'" class="csm-de">
              <button class="add-to-cart btn btn-outline-warning btn-sm" data-bs-toggle="modal" data-bs-target="#item-popup" @click="productModal(item)" v-if="checkInCart(item.item_id)">{{$t('Add')}}</button>
              <div v-else>                
                <div class="quantityInc 1">
                  <div class="number d-flex align-items-center">
                    <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                    <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                    <span class="plus d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#item-popup" @click="productModal(item)">+</span>
                  </div>
                </div>                
              </div>
            </div>
            <div v-else class="icus">
              <button v-if="item.is_sold_out == '0' && checkInCart(item.item_id)" data-bs-toggle="modal" class="add-to-cart btn btn-outline-warning btn-sm" @click="addProductToCart(item)">{{$t('Add')}}</button>
              <div v-else >
                <div class="quantityInc 2">
                  <div class="number d-flex align-items-center">
                    <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                    <!-- <input type="text" :value="cartItem.quantity" /> -->
                    <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                    <span class="plus d-flex justify-content-center align-items-center" @click="plusItem(item)">+</span>
                  </div>
                </div>
              </div>
            </div>
            <p v-if="item.is_customization" class="tag-customize tagcust-nw">{{$t('Customisable')}}</p>
          </div>
          <div v-else class="sold-bt">
              <button v-if="item.is_sold_out != '0'" class="sold add-to-cart btn btn-outline-warning btn-sm">{{$t('Sold Out')}}</button>
          </div>
        </div>
      </div>
    </div>
    <!-----Layout-3 End---->
    <!-----Layout-4 Start---->
    <div class="media media4" v-if="itemlayout == '4'">
      <div :class="item.image ==''?'image-sec':''">
        <div class="item-img itemimg-squre">
          <img :src="item.image" @error="onImgError()" v-if="!imgError" />
        </div>
      </div>
      <div class="nme-sec d-flex align-items-center">
        <a :href="(pagename=='itemslist')?'javascript:void(0)':'/vendor/'+ slug+'#item'+menuId" id="element" data-bs-toggle="modal" data-bs-target="#item-popup" class="btn btn-default show-modal" @click="productModal(item)">
          <div class="media-body">
              
                <div class="tags-list mb-1" v-if="item.item_tags && item.item_tags.length > 0">
                  <ul><li v-for="(tag,i) in item.item_tags" :key='i' :style="{ background: tag.background_color+'!important',color: tag.text_color}">{{tag.name}}</li></ul>
                </div>
                <div class="veg" v-if="item.item_type == '0'"><span></span></div>
                <div class="non-veg" v-if="item.item_type == '1'"><span></span></div>
                <h5 class="mt-0 me-5 item-name">{{ item.item_name }}</h5>
                
                <p class="mb-0 mt-2 item-descr" v-if="item.item_description">
                  {{(item.item_description && item.item_description.length > 80 ? item.item_description.substring(0,80)+".." :item.item_description) }}
                </p>
                <div class="prc-show">
                  <span class="mt-2 d-inline-block item-price" v-if="item.price != '0'"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.price).toFixed(2)}}</span>
                  <span class="mt-2 ms-2 d-inline-block item-price strikeout" v-if="item.mrp != item.price"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.mrp).toFixed(2)}}</span>                
                </div>              
          </div>
        </a>
        <div class="prc-btn-sec">
          <div v-if="!closeLabel" class="nw-itm-btn">
            <div v-if="item.is_sold_out == '0'" class="sold-de">
              <div v-if="item.is_customization >= '1'" class="csm-de">
                <button class="add-to-cart btn btn-outline-warning btn-sm" data-bs-toggle="modal" data-bs-target="#item-popup" @click="productModal(item)" v-if="checkInCart(item.item_id)">{{$t('Add')}}</button>
                <div v-else>                  
                  <div class="quantityInc 1">
                    <div class="number d-flex align-items-center">
                      <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                      <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                      <span class="plus d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#item-popup" @click="productModal(item)">+</span>
                    </div>
                  </div>                  
                </div>
              </div>
              <div v-else class="icus">
                <button v-if="item.is_sold_out == '0' && checkInCart(item.item_id)" data-bs-toggle="modal" class="add-to-cart btn btn-outline-warning btn-sm" @click="addProductToCart(item)">{{$t('Add')}}</button>
                <div v-else >
                  <div class="quantityInc 2">
                    <div class="number d-flex align-items-center">
                      <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                      <!-- <input type="text" :value="cartItem.quantity" /> -->
                      <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                      <span class="plus d-flex justify-content-center align-items-center" @click="plusItem(item)">+</span>
                    </div>
                  </div>
                </div>
              </div>
              <p v-if="item.is_customization" class="tag-customize tagcust-nw">{{$t('Customisable')}}</p>
            </div>
            <div v-else class="sold-bt">
                <button v-if="item.is_sold_out != '0'" class="sold add-to-cart btn btn-outline-warning btn-sm">{{$t('Sold Out')}}</button>
            </div>
          </div>
        </div>
      </div>
      
    </div>
    <!-----Layout-4 End---->
    <!-----Layout-5 Start---->
    <div class="media media5" v-if="itemlayout == '5' ">
      <a :href="(pagename=='itemslist' || pagename == 'qr')?'javascript:void(0)':'/vendor/'+ slug+'#item'+menuId" id="element"  data-bs-toggle="modal" data-bs-target="#item-popup" class="btn btn-default show-modal" @click="productModal(item)">
        <div class="media-body">
          <div class="veg" v-if="item.item_type == '0'"><span></span></div>
          <div class="non-veg" v-if="item.item_type == '1'"><span></span></div>
          <h5 class="mt-0 me-5 item-name">{{ item.item_name }}</h5>
          <div class="prc-show">
            <span class="mt-2 d-inline-block item-price" v-if="item.price != '0'"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.price).toFixed(2)}}</span>
            <span class="mt-2 ms-2 d-inline-block item-price strikeout" v-if="item.mrp != item.price"><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{(item.mrp).toFixed(2)}}</span>
          
          </div>
          <div class="tags-list mb-1" v-if="item.item_tags && item.item_tags.length > 0">
            <ul><li v-for="(tag,i) in item.item_tags" :key='i' :style="{ background: tag.background_color+'!important',color: tag.text_color}">{{tag.name}}</li></ul>
          </div>
          <p class="mb-0 mt-2 item-descr" v-if="item.item_description">
            {{(item.item_description && item.item_description.length > 80 ? item.item_description.substring(0,80)+".." :item.item_description) }}
          </p>
         
        </div>
      </a>
      <!--Image with button start----->
      <div class="img-btn">
        <div class="item-img itemimg-squre" >
          <img :src="item.image" @error="onImgError()" v-if="!imgError"/>
        </div>
        <div class="pgnme-cls btn-top" v-if="pagename != 'qr'">
          <div v-if="!closeLabel" class="nw-itm-btn">
            <div v-if="item.is_sold_out == '0'" class="sold-de">
              <div v-if="item.is_customization >= '1'" class="csm-de">
                <button class="add-to-cart btn btn-outline-warning btn-sm" @click="productModal(item)" data-bs-toggle="modal" data-bs-target="#item-popup" v-if="checkInCart(item.item_id)">{{$t('Add')}}</button>
                          
                  <div v-else class="quantityInc 1">
                    <div class="number d-flex align-items-center">
                      <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                      <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                      <span class="plus d-flex justify-content-center align-items-center" data-bs-toggle="modal" data-bs-target="#item-popup" @click="productModal(item)">+</span>
                    </div>                
                  </div>
              </div>
              <div v-else class="icus">
                <button v-if="item.is_sold_out == '0' && checkInCart(item.item_id)" data-bs-toggle="modal" class="add-to-cart btn btn-outline-warning btn-sm" @click="addProductToCart(item)">{{$t('Add')}}</button>
                <div v-else >
                  <div class="quantityInc 2">
                    <div class="number d-flex align-items-center">
                      <span class="minus d-flex justify-content-center align-items-center" @click="minusItem(item)">-</span>
                      <input type="text" :value="cart.reduce((a, c) => (a + (c.item_id == item.item_id ? c.quantity : 0)),0)" readonly/>
                      <span class="plus d-flex justify-content-center align-items-center" @click="plusItem(item)">+</span>
                    </div>
                  </div>
                </div>
              </div>
              <p v-if="item.is_customization" class="tag-customize tagcust-nw">{{$t('Customisable')}}</p>
            </div>
            <div v-else class="sold-bt">
              <button v-if="item.is_sold_out != '0'" class="sold add-to-cart btn btn-outline-warning btn-sm">{{$t('Sold Out')}}</button>
            </div>
        </div>
      </div>
    </div>    
   </div>
    <!-----Layout-5 End---->
    <!--Alert Modal start-->
    <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
    <!--Alert Modal End-->
  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations } from "vuex";
import AlertModal from '../components/AlertModal.vue';

export default {
  props: ["item", "menuId", "currencys","restaurantID","restaurant_on_off","pagename","slug","itemlayout","restautantDetails"],
  components: { AlertModal },
  data() {
    return {
      imgError: "",
      productId: "",
      noImgClass:'',
      closeLabel:false,
      isAlert : false,
      AlertData: {},
      tempProduct:{},
      layoutClass:'',
    };
  },
  mounted(){
    //Restaurant close or schedule for
    if(this.restaurant_on_off == '0' && this.$auth.setting && this.$auth.setting.is_enable_schedule_order_after_business_hours == 0){
      this.closeLabel = true;
    }else{
      this.closeLabel = false;
    }
    
    if(this.itemlayout == '3' || this.itemlayout == '2' || this.itemlayout == '4' ){
      this.layoutClass = 'secondlayout';
    }
  },
  
  computed: {
    ...mapGetters("product", ["cart"]),
  },
  
  methods: {
    ...mapActions("product", ["addProductToCart","removeProductFromCart"]),
    ...mapMutations("product",["decrementProductInventary","IncrementItemQuantity","clearCartItems"]),
    ...mapMutations("restaurant",["getRestaurantBySlug"]),
    
    onModalCloseFun(value){
      this.isAlert = false;
      if(value == 'yes'){
        this.$store.commit("product/clearCartItems");
        this.addProductCart(this.tempProduct);
      }
    },

    addProductToCart(product) {      
      var checkRestarant = this.cart.find((ele)=>(ele.restaurant_id == this.restaurantID));
      if(this.cart.length){
        if(checkRestarant){          
          this.addProductCart(product);
        }else{          
          this.AlertData ={
            displayMessage : this.$t('Your cart contain items from another ') + this.$t(this.$auth && this.$auth.getRestaurantName()) +this.$t(' Do you wish to clear cart and start a new order here?'),
            isSetClass : 'prdct-modal-cls'
          };          
          this.isAlert = true;
          this.tempProduct = product;          
          window.$("#AlertModalPlaced").modal("show");
          //window.$(this.$refs.AlertModal).modal();
        }
      }else{
        this.addProductCart(product);
      }
    },
    
    addProductCart(product){      
      let itemTax = 0;
      let savemrp = 0;
      if(product.item_tax != '0'){
       itemTax = (product.price - (product.price / (1+(product.item_tax/100))));
      }
      
      if(product.price < product.mrp){
        savemrp = product.mrp - product.price;        
      }
      var AddToCartItemArray = {
          vendor_id: this.$auth.getVendorId(),
          restaurant_id:product.restaurant_id,
          item_id: product.item_id,
          item_name: product.item_name,
          price: product.price,
          quantity: 1,
          itemTotalQuantity : product.quantity,
          selected_type: [],
          selectCustoizeID:[],
          selectCustoizePrice: [],
          item_type:product.item_type,
          item_price:product.price,
          image:product.image,
          saveprice:savemrp,
          item_tax:itemTax,
          mrp:product.mrp,
          packaging_charge:product.item_packaging_charge
      };
      this.$store.commit("restaurant/getRestaurantBySlug", this.restautantDetails);
      this.$store.dispatch("product/addProductToCart", AddToCartItemArray);
    },

    productModal(item) {      
      item.restaurant_on_off = this.restaurant_on_off;
      item.restaurant_id = this.restaurantID;
      this.$emit("openProductDetailModal", item);
    },

    plusItem(product){
      const cartItemIndex = this.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
      var itemTotalQuantity = this.cart[cartItemIndex].quantity;
      let cartItem = {
        'cartItemIndex':cartItemIndex,
        'product':product
      };
      if(itemTotalQuantity < product.quantity) {
        this.$store.commit("product/IncrementItemQuantity", cartItem);
      }else{
        alert(this.$t("Maximum items reached"));
      }
    },

    minusItem(product){      
      const cartItemIndex = this.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
      let  totalSmCartID = [];
        this.cart.forEach(item => {
          if(item.item_id === product.item_id){
            totalSmCartID.push(item.item_id);
          }
        });
      if(totalSmCartID.length > 1){
        alert(this.$t("Please Remove Item From Cart!"));
      }else{
        var itemQuantity = this.cart[cartItemIndex].quantity;
        if(itemQuantity == '1' || itemQuantity == '0'){
          this.deleteProduct(cartItemIndex);
        }else{
          this.$store.commit("product/decrementProductInventary", cartItemIndex);
        }
      }
      return false;     
    },
    
    deleteProduct(index) {
      this.$store.dispatch("product/removeProductFromCart",index)
    },

    checkInCart(itemId){
      var checkItem = this.cart.find((ele)=>(ele.item_id == itemId));
      if(checkItem){
        return false;
      }else{
        return true;
      }
    },

    onImgError() {
      this.imgError = true;
      this.noImgClass = 'noImgClass';
    },   
  },
};
</script>
<style>
.media-itms-theme5 .sold-de{
  /* width: 120px !important; */
  margin: 0;
}


.sold-bt button.sold,
.sold-de .csm-de button {
  /* width: 100%; */
  margin-top: 4px !important;
  height: 30px;
}

</style>