<template>
<div>
  <div class="modal fade prdtmodel-nw orders-tab-main cust-prdt-table" ref="productDetailsModal" id="item-popup" tabindex="-1" aria-labelledby="itemModalLabel" >
  <!-- <div class="modal fade"  id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >   -->
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <skeleton-loader-vue v-if="!productDetail" type="string" width="50%" height="20px" />
          <h5 v-else class="modal-title" id="itemModalLabel">{{ productDetail.item_name }}</h5>
          <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
          <div class="tags-list tagslist" v-if="tags && tags.length > 0">
            <ul>
              <li v-for="(tag,i) in tags" :key='i' :style="{ background: tag.background_color+'!important',color: tag.text_color }">{{tag.name}}</li>
            </ul>
          </div>
        </div>
        <!---------Customization Skeleton start------------->
        <div class="container" v-if="customize_skelloader">
          <div class="product-content pt-3">
            <div>
              <div class="cus-title">
                <skeleton-loader-vue type="string" width="37%" height="20px" />
                <skeleton-loader-vue type="string" width="20%" height="20px" />
                </div>
              <div>
                <div class="">
                  <label for="radio-0-0" class="form__label-radio" style="padding-left: 0px">
                    <skeleton-loader-vue type="circle" class="form__radio" width="20px" height="20px" style="margin-right: 20px;" />
                    <skeleton-loader-vue type="string" width="170px" height="20px" style="position: absolute;" />
                  </label>
                  <span class="ch-price"><skeleton-loader-vue type="string" width="30px" height="24px" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!---------Customization Skeleton End------------->
        <div class="modal-body" v-if="productDetail" :id="'item-id-' + productDetail.item_id">
          <VueSlickCarousel v-bind="settings" id="prod-details-slider" class="silkprod" v-if="productDetail.item_images.length > 0">            
            <div v-for="(slide,index) in productDetail.item_images" :key="index">              
              <div class="testimonial-item equal-height style-6">
                <img class="item-img" :src="slide.image_name" @error="onImgError()" />
              </div>
            </div>
          </VueSlickCarousel>
          <ul class="nav nav-tabs border-0 mb-3" v-if="productDetail.customize_type.length > 0 && productDetail.item_description">
            <li class="nav-item">
              <a href="#sort" class="nav-link navbar-default" :class="productDetail.is_customizable.length > 0 ? 'active' : ''" v-if="productDetail.customize_type.length > 0" data-bs-toggle="tab">{{$t('Customization')}}</a>
            </li>
            <li class="nav-item"  v-if="productDetail.item_description || productDetail.custom_details.length > 0">
              <a href="#cusines" class="nav-link navbar-default" data-bs-toggle="tab">{{$t('Info')}}</a>
            </li>
          </ul>
          <form action="javascript:void(0)">
            <div class="tab-content">            
              <div id="sort" class="tab-pane active tab-box" v-if="productDetail.customize_type.length > 0">
                <div class="container">
                  <div class="product-content pt-3" v-if="productDetail.customize_type.length > 0 && !customize_skelloader">
                    <div v-for="(customize, customizeIndex) in productDetail.customize_type" :key="customizeIndex">
                      <div class="cus-title">
                          <h6 class="cus-name">{{ customize.name }}</h6>
                          <p v-if="customize.is_selection != 0">Maximum {{ customize.is_selection }} items</p>
                      </div>
                      <!--Radio Button start-->
                      <div v-if="customize.is_min_selection == '1' && customize.is_selection == '1' || customize.is_selection == '1'" :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$error}">
                        <div class="form-check form__radio-group" v-for="(optionData, index) in customize.option" :key="index">
                            <div data-bs-toggle="collapse" class="rdiotogl" :href="'#collapseExample'+customizeIndex+index">
                              <input class="form__radio-input" type="radio" :name="'radio-' + customizeIndex" :id="'radio-' + customizeIndex + '-' + index" :value="optionData.selection_id" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$model" @change="checkNested($event,customize.customize_type_id)" />
                              <label class="form__label-radio" :for="'radio-' + customizeIndex + '-' + index"><span class="form__radio-button"></span>{{ optionData.selection_name }}</label>
                              <span class="ch-price">
                                <span v-html="$auth.setting.currency" class="currency"></span>
                                {{ optionData.selection_price }}
                                <b-icon-arrow-right-circle-fill v-if="optionData.options.length > 0"></b-icon-arrow-right-circle-fill>
                              </span>
                            </div>
                            <!--------Sub Customization Start Radio------------->
                            <div class="rdiotogllist" v-if="optionData.is_min_selection == '1' && optionData.is_selection == '1' || optionData.is_selection == '1' && optionData.options.length > 0 ">
                              <div class="chld-rdio collapse show" :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$error}" v-if="optionData.options.length > 0 && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value == optionData.selection_id">
                                <div v-for="(subOption,subi) in optionData.options" :key="subi" class="form-check form__radio-group">
                                  <!-- v-model="productDetail.selected_type[customizeIndex].subradio"  -->
                                  <input class="form__radio-input" type="radio" :name="'radio-' +index+'-'+ customizeIndex" :id="'radio-' + customizeIndex + '-' +index+'-'+subi" :value="subOption.selection_id" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$model" @change="checkNestedSub($event,subOption.selection_id)" />
                                  {{subOption.is_selected}}
                                  <label class="form__label-radio" :for="'radio-' + customizeIndex + '-' +index+'-'+subi"><span class="form__radio-button"></span>{{ subOption.selection_name }}</label>
                                  <span class="ch-price"><span v-html="$auth.setting.currency" class="currency"></span>{{ subOption.selection_price }}</span>
                                </div>
                              </div>

                              <div class="invalid-feedback">
                                <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.crequired" >{{optionData.selection_name}} {{$t('Maximum')}} {{optionData.is_selection}} {{$t('item select')}}</span>
                                <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.valueBetween" >{{optionData.selection_name}} {{$t('Maximum')}} {{optionData.is_selection}} {{$t('item select valid')}}</span>
                              </div>
                            </div>
                            <!---------Sub Customization End Radio------------>
                            <!---------Sub Customization Start Checkbox------------>
                            <div v-else class="custom-checkbox w-100">
                                <div class="chld-rdio collapse show"
                                :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$error}"
                                v-if="optionData.options.length > 0 && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value == optionData.selection_id">
                                    <div class="form-group" v-for="(suboptionCheckbox, subindex) in optionData.options" :key="subindex">
                                        <!-- v-model="productDetail.selected_type[customizeIndex].subcheckbox" -->
                                        <input class="form-check-input" type="checkbox" :value="suboptionCheckbox.selection_id" :id="'subcheckbox-' + customizeIndex + '-' + index +'-'+ subindex" :name="'subcheckbox_' + customizeIndex+index+'_'+subindex" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$model" @change="checkNestedSub($event)" />{{suboptionCheckbox.is_selected}}
                                        <label class="form-check-label" :for="'subcheckbox-' + customizeIndex + '-' + index+'-'+subindex">
                                            {{ suboptionCheckbox.selection_name }}</label>
                                        <span class="ch-price"><span v-html="$auth.setting.currency" class="currency"></span>{{ suboptionCheckbox.selection_price }}</span>
                                    </div>
                                </div>
                                <div class="invalid-feedback">
                                    <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.crequired" >{{optionData.selection_name}} Maximum {{optionData.is_selection}} item select</span>
                                    <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.valueBetween" >{{optionData.selection_name}} Maximum {{optionData.is_selection}} item select valid</span>
                                </div>
                            </div>
                            <!---------Sub Customization End Checkbox------------>
                        </div>
                      </div>
                      <!--Radio Button end-->
                      <!--CheckBox start-->
                        <div v-else class="custom-checkbox" :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$error}">
                          <div  v-for="(optionData, index) in customize.option" :key="index">
                          <div class="form-group">
                            <input class="form-check-input" type="checkbox" :value="optionData.selection_id" :id="'checkbox-' + customizeIndex + '-' + index" :name="'checkbox_' + customizeIndex" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$model" @change="checkNestedSub($event,customize.customize_type_id)"  />
                            <label class="form-check-label" :for="'checkbox-' + customizeIndex + '-' + index"> {{ optionData.selection_name }} {{ customize.customizeIndex }}</label>
                            <span class="ch-price"><span v-html="$auth.setting.currency" class="currency"></span>{{ optionData.selection_price }} <b-icon-arrow-right-circle-fill v-if="optionData.options.length > 0"></b-icon-arrow-right-circle-fill></span>
                          </div>
                          <!--------Sub Customization Start Radio------------->
                          <div v-if="optionData.is_min_selection == '1' && optionData.is_selection == '1' || optionData.is_selection == '1' && optionData.options.length > 0 ">
                            <div class="chld-rdio" :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$error}" v-if="optionData.options.length > 0 && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value == optionData.selection_id">
                              <div v-for="(subOption,subi) in optionData.options" :key="subi" class="form-check form__radio-group">
                                <!-- v-model="productDetail.selected_type[customizeIndex].subradio"  -->
                                <input class="form__radio-input" type="radio" :name="'radio-' +index+'-'+ customizeIndex" :id="'radio-' + customizeIndex + '-' +index+'-'+subi" :value="subOption.selection_id" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$model" @change="checkNestedSub($event)" />
                                {{subOption.is_selected}}
                                <label class="form__label-radio" :for="'radio-' + customizeIndex + '-' +index+'-'+subi"><span class="form__radio-button"></span>{{ subOption.selection_name }}</label>
                                <span class="ch-price"><span v-html="$auth.setting.currency" class="currency"></span>{{ subOption.selection_price }}</span>
                              </div>
                            </div>
                            <div class="invalid-feedback">
                              <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.crequired" >{{optionData.selection_name}} {{$t('Maximum 1 item select')}}</span>
                              <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.valueBetween" >{{optionData.selection_name}} {{$t('Maximum 1 item select valid')}}</span>
                            </div>
                          </div>
                          <!---------Sub Customization End Radio------------>
                          <!---------Sub Customization Start Checkbox------------>
                          <div v-else class="custom-checkbox w-100">
                            <div class="chld-rdio" :class="{'is-invalid': $v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$error}" v-if="optionData.options.length > 0  && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value && selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.includes(optionData.selection_id)">
                            <div class="form-group" v-for="(suboptionCheckbox, subindex) in optionData.options" :key="subindex">
                              <!-- v-model="productDetail.selected_type[customizeIndex].subcheckbox" -->
                              <input class="form-check-input" :data-id="customize.customize_type_id+','+optionData.selection_id" type="checkbox" :value="suboptionCheckbox.selection_id" :id="'subcheckbox-' + customizeIndex + '-' + index +'-'+ subindex" v-model="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.$model" @change="checkNestedSub($event,suboptionCheckbox.customize_type_id,suboptionCheckbox.parent_item_id)"   />{{suboptionCheckbox.is_selected}}
                              <label class="form-check-label" :for="'subcheckbox-' + customizeIndex + '-' + index+'-'+subindex">
                                  {{ suboptionCheckbox.selection_name }}</label>
                              <span class="ch-price"><span v-html="$auth.setting.currency" class="currency"></span>{{ suboptionCheckbox.selection_price }}</span>
                              </div>
                            </div>
                            <div class="invalid-feedback">
                              <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.crequired" >{{optionData.selection_name}} {{$t('Maximum')}} {{optionData.is_selection}} {{$t('item select')}}</span>
                              <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id]['child_'+optionData.selection_id].value.valueBetween" >{{optionData.selection_name}} {{$t('Maximum')}} {{optionData.is_selection}} {{$t('item select valid')}}</span>
                            </div>
                          </div>
                          <!---------Sub Customization End Checkbox------------>
                          </div>
                      </div>
                      <!--CheckBox end-->
                      <div class="invalid-feedback" v-if="$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.$error">
                          <span v-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.crequired">
                              <span v-if="customize.is_min_selection == 1">{{customize.name}} {{$t('Required Minimun 1 item select')}}</span>
                              <span v-if="customize.is_min_selection != 1 ">{{customize.name}} {{$t('Required Minimum')}} {{customize.is_min_selection}} {{$t('items')}}</span>
                          </span>
                          <span v-else-if="!$v.selectedCustomisation.customize_type['parent_'+customize.customize_type_id].value.valueBetween">
                              <span v-if="customize.is_min_selection == 1">{{customize.name}} {{$t('Required Minimun 1 item select')}}</span>
                              <span v-if="customize.is_min_selection != 1">{{customize.name}} {{$t('Required Minimum')}} {{customize.is_selection}} {{$t('items')}}</span>
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>            
              <div id="cusines" class="tab-pane tab-box" :class="(productDetail.customize_type.length == '0' && (productDetail.item_description || productDetail.custom_details.length != 0))?'active':''">
                <div class="accordion mt-4" id="accordionExample">
                    <div class="card" v-if="productDetail.item_description">
                        <div class="card-header" id="headingOne">
                            <h2 class="mb-0">
                              <button class="btn btn-link btn-block text-start collapsed w-100" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Description</button>
                            </h2>
                        </div>
                        <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                          <div class="card-body">{{ productDetail.item_description }}</div>
                        </div>
                    </div>
                    <div v-if="productDetail.custom_details.length > 0">
                      <div class="card" v-for="(detail,index) in productDetail.custom_details" :key="index+1">
                        <div class="card-header" :id="`headingOne-${index}`">
                          <h2 class="mb-0">
                          <button class="btn btn-link btn-block text-start collapsed w-100" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapseOne-${index}`" aria-expanded="true" :aria-controls="`collapseOne-${index}`">
                          {{detail.custom_key}}
                          </button>
                          </h2>
                        </div>
                        <div  :id="`collapseOne-${index}`" class="collapse" :aria-labelledby="`headingOne-${index}`" data-parent="#accordionExample"> 
                          <div class="card-body">{{detail.custom_value}}</div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </form>                
        </div>
        <div class="modal-footer" v-if="!closeLabel && pagename != 'qr'" >
          <div class="quantityInc" v-if="productDetail && productDetail.is_sold_out == '0'">
              <div class="number d-flex align-items-center">
                  <span class="minus d-flex justify-content-center align-items-center" @click="decrementItem()">-</span>
                  <input type="text" :value="quantityTotal" readonly />
                  <span class="plus d-flex justify-content-center align-items-center" @click="incrementItem()">+</span>
              </div>
          </div>
          <button v-if="productDetail && productDetail.is_sold_out == '0'" class="add-to-cart btn btn-outline-warning btn-sm" @click="addProductToCart">{{$t('Add To Cart')}}(<span v-html="$auth.setting.currency" class="currency"></span>
            {{(getTotalOptionPrice()*this.quantityTotal).toFixed(2)}})
          </button>
          <button v-if="productDetail && productDetail.is_sold_out != '0' && !customize_skelloader"  class="sold add-to-cart btn btn-outline-warning btn-sm">{{$t('Sold Out')}}</button>
        </div>
      </div>
    </div>
  </div>
  <!--Alert Modal start-->
  <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
  <!--Alert Modal End-->  
</div>
</template>

<script>
import {
    mapGetters,
    mapActions,
    mapMutations
} from "vuex";
import AlertModal from '../components/AlertModal.vue';
import VueSkeletonLoader from 'skeleton-loader-vue';
import {requiredIf} from 'vuelidate/lib/validators';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
let flag = true;
export default {
    components: {AlertModal, 'skeleton-loader-vue': VueSkeletonLoader,VueSlickCarousel},    
    props: ["productId", "restaurantID", "restaurant_on_off","pagename"],
    data() {
      return {
        productDetail: null,
        imgError: "",
        loading: true,
        optionCollection: [],
        selectedCustomisation: {
          customize_type: {}
        },
        selectedCheckbox: [],
        itemTotal: 0,
        quantityTotal: 1,
        closeLabel: false,
        isAlert: false,
        AlertData: {},
        tempProduct: {},
        customize_skelloader: true,
        settings:{
            "autoplay": true,
            "dots": false,
            "arrows": true,
            "infinite": true,
            "speed": 800,
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "initialSlide": 0,
            "centerMode": false,
            "centerPadding":'40px',
            "responsive": [
                {
                "breakpoint": 1024,
                "settings": {
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    "infinite": true,
                    "dots": true
                }
                },
                {
                "breakpoint": 600,
                "settings": {
                    "slidesToShow": 1,
                    "slidesToScroll": 1,
                    "initialSlide": 1
                }
                },
                {
                "breakpoint": 480,
                "settings": {
                    "slidesToShow": 1,
                    "slidesToScroll": 1
                }
                }
            ]
        },
        tags:[],
        isDefaultCheck:false, 
        isChildDefaultCheck:false,
      };
    },

    mounted() {
      if(this.restaurant_on_off == '0' && this.$auth.setting && this.$auth.setting.is_enable_schedule_order_after_business_hours == 0){
        this.closeLabel = true;
      }else{
        this.closeLabel = false;
      }      
      // const myModal = new bootstrap.Modal('#item-popup', {
      //     keyboard: false,
      //   })
      // $(this.$refs.productDetailsModal).on("hidden.bs.modal", this.ClosedModal);      
    },
    validations() {
      if(this.productDetail){
        let data = {
          selectedCustomisation:{
            customize_type:{}
          }
        }
        this.productDetail && this.productDetail.customize_type.forEach((item,index)=>{
          let pvalue = null;
          if(!this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]){
            this.selectedCustomisation.customize_type['parent_'+item.customize_type_id] = {value: item.is_selection != 1?[]:null}
          }
          pvalue = this.selectedCustomisation.customize_type['parent_'+item.customize_type_id].value;
          data['selectedCustomisation']['customize_type']['parent_'+item.customize_type_id] = {
              'value':{
                  crequired: ((value) => {
                    return item.is_min_selection > 0 ? (value && value.toString().length > 0 ? true : false) : true;
              } ),
              valueBetween: ((value) => item.is_selection > 1 ?
                value.length >= item.is_min_selection && value.length <= item.is_selection : true)
            }
          }
          this.productDetail.customize_type[index].option.forEach((op, ind) => {             
            if(op.is_default == 1 && this.selectedCustomisation.customize_type['parent_'+op.customize_type_id].flag !== false){
              //this.isDefaultCheck = true;
              let setvalue = this.selectedCustomisation.customize_type['parent_'+op.customize_type_id].value;                
              if(setvalue && setvalue != null) {
                if(typeof setvalue == 'number' || typeof setvalue == 'string') {
                    setvalue = op.selection_id;
                }else{
                  if(!setvalue.includes(op.selection_id)){
                    setvalue.push(op.selection_id);
                  }
                }
              }else{
                this.selectedCustomisation.customize_type['parent_'+op.customize_type_id].value = op.selection_id;
              }
            }
            this.selectedCustomisation.customize_type['parent_'+op.customize_type_id].flag = false;
            let temp = this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id];
            if(!temp || (pvalue && typeof pvalue == 'object' && (pvalue.length == 0 || !pvalue.includes(op.selection_id)) || pvalue == null || pvalue == '') ){
              if(op.is_selection < op.is_min_selection){
                this.productDetail.customize_type[index].option[ind].is_selection = op.is_min_selection;
              }
              this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id] = {value: op.is_selection != 1?[]:op.is_selection < op.is_min_selection?[]: null}
              this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id].flag = true;
              //this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id].flag = true;
            }
            if(op.options.length > 0) {
              op.options.forEach((itm,key)=> {
                //if(itm.is_default == 1  && !this.isChildDefaultCheck) {
                if(itm.is_default == 1 && this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id].flag !== false) {
                  let setopvalue = this.selectedCustomisation.customize_type['parent_'+op.customize_type_id]['child_'+itm.parent_item_id].value;
                  if(setopvalue != null ){
                    if(typeof setopvalue == 'number' || typeof setopvalue == 'string') {
                      setopvalue = itm.selection_id;
                    }else{
                      if(setopvalue.length > 0){
                        setopvalue = setopvalue.filter((c, index) => {return setopvalue.indexOf(c) === index;});
                      }
                      setopvalue.push(itm.selection_id);
                    }
                  }
                  //this.isChildDefaultCheck = true;
                }
              })
            }else{
              if(op.is_default == 1 && this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id].flag !== false) {
                let setopvalue = this.selectedCustomisation.customize_type['parent_'+op.customize_type_id].value;                
                if(setopvalue == null ) {
                  this.selectedCustomisation.customize_type['parent_'+op.customize_type_id].value = op.selection_id;
                }
              }
            }
            this.selectedCustomisation.customize_type['parent_'+item.customize_type_id]['child_'+op.selection_id].flag = false;
            data['selectedCustomisation']['customize_type']['parent_'+item.customize_type_id]['child_'+op.selection_id] = {
              'value':{
                crequired: ((value)=> {                  
                    if(typeof value != 'object') {                      
                        return (op.is_min_selection > 0 && (pvalue &&  typeof pvalue == 'object' ? pvalue.includes(op.selection_id) : pvalue == op.selection_id)
                        ? (value && value.toString().length > 0 ? true : false) : true)
                    }else{                      
                    return (op.is_min_selection > 0 && 
                    (pvalue && typeof pvalue == 'object' ? pvalue.includes(op.selection_id) : pvalue == op.selection_id)
                    ? (value && value.length > 0 ? true : false) : true)
                    }
                }),
                
                valueBetween: ((value) => {
                    if(typeof value != 'object'){
                        return op.is_selection > 1 && 
                        (pvalue && typeof pvalue == 'object' ? pvalue.includes(op.selection_id) : pvalue == op.selection_id) ?
                        value && value.toString().length >= op.is_min_selection &&
                        value && value.toString().length <= op.is_selection : true;
                    }
                    return op.is_selection > 1 && 
                        (pvalue && typeof pvalue == 'object' ? pvalue.includes(op.selection_id) : pvalue == op.selection_id)?
                        value && value.length >= op.is_min_selection &&
                        value && value.length <= op.is_selection : true;
                })
              }
            }
          })          
        });
        //this.isDefaultCheck = false;
        return data;
      }else{
        return {}
      }
    },

    computed: {
      ...mapGetters("product", ["cart"]),
    },
   
    methods:{
      ...mapActions("product", ["addProductToCart", "getProductDetails"]),
      ...mapMutations("product", ["clearCartItems"]),

      openProductModel(itemId) {
        this.itemTotal = 0;
        this.quantityTotal = 1;
        this.loading = true;
        this.customize_skelloader = true;          
        this.getProductDetails({
          vendor_id: this.$auth.getVendorId(),
          is_langauge: this.$store.state.lang.locale,
          latitude: localStorage.getItem("latitude"),
          starttime: "",
          longitude: localStorage.getItem("longitude"),
          restaurant_id: this.restaurantID,
          date: "",
          item_id: itemId,
        }).then((data) => {
          if (data.code == 200) {
            this.loading = false;
            if (data.Result[0] != undefined && data.Result[0].length != 0) {
              this.customize_skelloader = false;
              this.productDetail = {
                  ...data.Result[0],
                  selected_type: data.Result[0].customize_type.map((p) => ({
                      customize_type_id: p.customize_type_id,
                      max_selection: parseInt(p.is_selection),
                      min_selection: parseInt(p.is_min_selection),
                      selection_name: p.name,

                      ...(p.is_selection != 1 ? {
                          option: p.option.filter(
                              (q) =>
                              q.is_default == "1"
                          )
                      } : {
                          option: []
                      }),
                      ...(p.is_selection == 1 ? {
                          roption: p.option.find(
                              (q) => q.is_default == "1"
                          )
                      } : {
                          roption: undefined
                      }),
                  })),
              };
              this.tags = this.productDetail.item_tags;                   
            }
          }
        });
      },

        //Display Total Amount Of item cart
      getTotalOptionPrice() {
        let radioTotal = 0;
        let checkboxTotal = 0;
        let pvalue = null;
        this.productDetail.customize_type.forEach((itm,index)=>{
          // let parentId = 'parent_'+itm.customize_type_id;                
          if(this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id]){
            pvalue = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id].value;                    
          }else{
            pvalue = null;
          }            
          if(itm.option){
              itm.option.forEach((itmoption,key)=>{
              if(pvalue && pvalue != null && typeof pvalue != 'number') {
                if(pvalue && pvalue != null && pvalue.includes(itmoption.selection_id)) {                    
                  radioTotal += itmoption.selection_price;
                  if(itmoption.options) {
                    let temp = null;
                    itmoption.options.forEach((option,okey)=>{
                      temp = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id]['child_'+option.parent_item_id].value;
                      if(temp && typeof temp == 'number' && temp == option.selection_id){
                        radioTotal += option.selection_price;
                      }
                      if(temp && typeof temp == 'object' && temp.includes(option.selection_id)){
                        radioTotal += option.selection_price;
                      }
                    })
                  }
                }
              }else if(pvalue && pvalue != null && typeof pvalue == 'number'){
                if(pvalue && pvalue == itmoption.selection_id){
                  radioTotal += itmoption.selection_price;
                  if(itmoption.options.length > 0) {                       
                    let temp = null;
                    itmoption.options.forEach((option,okey)=>{
                      temp = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id]['child_'+option.parent_item_id].value;
                      if(temp && typeof temp == 'number' && temp == option.selection_id){
                        radioTotal += option.selection_price;
                      }
                      if(temp && typeof temp == 'object' && temp.includes(option.selection_id)){
                        radioTotal += option.selection_price;
                      }
                    })
                  }                               
                }
              }
            })
          }
        })          
        return this.productDetail.price + radioTotal + checkboxTotal;
      },

      onModalCloseFun(value) {
        this.isAlert = false;
        if (value == 'yes') {
          this.$store.commit("product/clearCartItems");
          localStorage.setItem('orderUploadImages', '');
          this.addToCartItem(this.tempProduct);
        }
      },

      addProductToCart() {
        this.$v.$touch();
        if(this.$v.selectedCustomisation.customize_type.$invalid){
          return false;
        }
        let pvalue = null;
        let arraySelectCustoizeID = [];
        let arraySelectCustoizePrice = [];
        let arraySelectCustoizPackge = [];
        let selected_type = [];
        let packagingCharge = 0;
        this.productDetail.customize_type.forEach((itm,index)=>{
          // let parentId = 'parent_'+itm.customize_type_id;
          pvalue = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id].value;
          if(itm.option){
            itm.option.forEach((itmoption,key)=>{                     
              if(pvalue && pvalue != null && typeof pvalue != 'number') {
                if(pvalue && pvalue != null && pvalue.includes(itmoption.selection_id)){
                  arraySelectCustoizeID.push(itmoption.selection_id);
                  arraySelectCustoizePrice.push(itmoption.selection_price);
                  selected_type.push(itmoption.selection_name);
                  arraySelectCustoizPackge.push(parseInt(itmoption.packaging_charge));
                  if(itmoption.options) {
                    let temp = null;
                    itmoption.options.forEach((option,okey)=>{
                      temp = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id]['child_'+option.parent_item_id].value;
                      if(temp && typeof temp == 'number' && temp == option.selection_id){
                        arraySelectCustoizeID.push(option.selection_id);
                        arraySelectCustoizePrice.push(option.selection_price);
                        selected_type.push(option.selection_name);
                        arraySelectCustoizPackge.push(parseInt(option.packaging_charge));
                      }
                      if(temp && typeof temp == 'object' && temp.includes(option.selection_id)){
                        arraySelectCustoizeID.push(option.selection_id);
                        arraySelectCustoizePrice.push(option.selection_price);
                        selected_type.push(option.selection_name);
                        arraySelectCustoizPackge.push(parseInt(option.packaging_charge));
                      }
                    });
                  }
                }
              }else if(pvalue && pvalue != null && typeof pvalue == 'number'){
                if(pvalue && pvalue == itmoption.selection_id){
                  arraySelectCustoizeID.push(itmoption.selection_id);
                  arraySelectCustoizePrice.push(itmoption.selection_price);
                  selected_type.push(itmoption.selection_name);
                  arraySelectCustoizPackge.push(parseInt(itmoption.packaging_charge));
                  if(itmoption.options) {
                    let temp = null;
                    itmoption.options.forEach((option,okey)=>{
                        temp = this.selectedCustomisation.customize_type['parent_'+itm.customize_type_id]['child_'+option.parent_item_id].value;
                        if(temp && typeof temp == 'number' && temp == option.selection_id){
                            arraySelectCustoizeID.push(option.selection_id);
                            arraySelectCustoizePrice.push(option.selection_price);
                            selected_type.push(option.selection_name);
                            arraySelectCustoizPackge.push(parseInt(option.packaging_charge));
                        }
                        if(temp && typeof temp == 'object' && temp.includes(option.selection_id)){
                            arraySelectCustoizeID.push(option.selection_id);
                            arraySelectCustoizePrice.push(option.selection_price);
                            selected_type.push(option.selection_name);
                            arraySelectCustoizPackge.push(parseInt(option.packaging_charge));
                        }
                    });
                  }
                }
              }
            })
          }
        })
        packagingCharge = arraySelectCustoizPackge.reduce((partialSum, a) => partialSum + a, 0);
        let savemrp = 0;
        let itemTax = 0;
        if(this.productDetail.price < this.productDetail.mrp){
          savemrp = this.productDetail.mrp - this.productDetail.price;                
        }
        if(this.productDetail.item_tax != '0'){
          itemTax = (this.productDetail.price - (this.productDetail.price / (1+(this.productDetail.item_tax/100))));
        }
        var AddToCartItemArray = {
          restaurant_id: this.restaurantID,
          item_id: parseInt(this.productDetail.item_id),
          item_name: this.productDetail.item_name,
          price: this.getTotalOptionPrice(),
          quantity: this.quantityTotal,
          itemTotalQuantity: this.productDetail.quantity,
          selected_type: selected_type,
          selectCustoizeID: arraySelectCustoizeID,
          selectCustoizePrice: arraySelectCustoizePrice,
          item_type: this.productDetail.item_type,
          item_price: this.productDetail.price,
          image:this.productDetail.image,
          saveprice:savemrp,
          packageCharge:packagingCharge,
          item_tax:itemTax,
          mrp:this.productDetail.mrp,
          packaging_charge:this.productDetail.item_packaging_charge
        };
        var checkRestarant = this.cart.find((ele) => (ele.restaurant_id == this.restaurantID));
        if (this.cart.length) {
          if (checkRestarant) {
            this.addToCartItem(AddToCartItemArray);
          } else {
            this.AlertData = {
              displayMessage: this.$t('Your cart contain items from another ')+ this.$t(this.$auth && this.$auth.getRestaurantName()) +this.$t('Do you wish to clear cart and start a new order here?'),
              isSetClass: 'prdct-modal-cls'
            };
            this.isAlert = true;
            this.tempProduct = AddToCartItemArray;
            window.$("#item-popup").modal("hide");
            window.$(this.$refs.AlertModal).modal();
          }
        } else {
          this.addToCartItem(AddToCartItemArray);
        }
      },

      addToCartItem(product) {
        const cartItemIndex = this.cart.findIndex(item => parseInt(item.item_id) === parseInt(product.item_id));
        let totalQnt = 0;
        if(cartItemIndex != undefined && cartItemIndex != -1 ) {
            var itemTotalQuantity = this.cart[cartItemIndex].quantity;              
            totalQnt = this.cart[cartItemIndex].quantity + product.quantity;
        } else {
          totalQnt = product.quantity;
        }          
        if(totalQnt <= product.itemTotalQuantity){
            this.$store.dispatch("product/addProductToCart", product);
            window.$("#item-popup").modal("hide");
        } else {
          this.AlertData = {
          displayMessage: this.$t('"Maximum items reached'),
          isSetClass: 'cart-modal-cls'
          };
          this.isAlert = true;
          window.$(this.$refs.AlertModal).modal();
          // alert(this.$t("Maximum items reached"));
        }
      },

      checkNested(e, cust_id){
        this.selectedCustomisation.customize_type = {
          ...this.selectedCustomisation.customize_type,
          ['parent_'+cust_id]:{value : e.target.value}  
        }
      },

      checkNestedSub(e, cust_id,selectionid){
        let types = Object.keys(this.selectedCustomisation.customize_type);                    
        this.selectedCustomisation.customize_type = {
          ...this.selectedCustomisation.customize_type,
            /* ...(types.reduce((acc,ire) => {
                acc[ire] = {...this.selectedCustomisation.customize_type[ire]};
                return acc;
            },{})) */
        }                    
      },

      incrementItem() {
        if (this.productDetail.quantity > this.quantityTotal) {
          this.quantityTotal++;
        }else{
          this.AlertData = {
          displayMessage: this.$t('"Maximum items reached'),
          isSetClass: 'cart-modal-cls'
          };
          this.isAlert = true;
          window.$(this.$refs.AlertModal).modal();
          // alert(this.$t("Maximum items reached"));
        }
      },

      decrementItem() {
        if (this.quantityTotal != 1) {
          this.quantityTotal--;
        }
      },

      checkInCart(itemId) {
        var checkItem = this.cart.find((ele) => ele.item_id == itemId);
        if (checkItem) {
            return false;
        } else {
            return true;
        }
      },

      onImgError() {
        this.imgError = true;
      },

      ClosedModal(){
        this.isDefaultCheck = false;
        this.isChildDefaultCheck = false;
      },
    },
};
</script>
<style>
.silkprod{padding-bottom: 30px;}
.silkprod .testimonial-item .item-img{
  display: block;
  width: 70%;
  margin: 0 auto;
  float: left;
  height: 185px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}
.silkprod .slick-arrow{
  width: 36px;
  border: 1px solid rgba(var(--theme-primary));
  border-radius: 50%;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.silkprod .slick-prev::before{
  content: "\f104";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: rgba(var(--theme-primary));
  line-height:0.2;
  font-size:20px;
}
.silkprod .slick-next::before {
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  line-height:0.2;
  color: rgba(var(--theme-primary));
  font-size:20px;
}
.prdtmodel-nw .slick-list{
  width: 70%;
  margin: 0 auto;
}
.silkprod .slick-prev{
  display: block;
  z-index: 99 !important;
  left: 5% !important;
}
.silkprod .slick-next{
display: block;
z-index: 99 !important;
right: 5%;
}
.silkprod .slick-arrow{
  top: 50%;
  translate: 0 -50%;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
}
@media all and (max-width:767px){
.cust-prdt-table .modal-content{width: 95%;}
.silkprod .slick-prev{left: 35% !important;}
.silkprod .slick-next{right: 35% !important;}
}
</style>